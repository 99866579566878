import { DevBoxActionContract, DevBoxNextActionContract } from '../data/contracts/dev-box-action';
import { SerializableMap } from '../types/serializable-map';
import { UnionValueMap } from '../types/union-map';

/**
 * Application models
 */

export type DevBoxAction = Omit<DevBoxActionContract, 'next' | 'suspendedUntil'> & {
    next?: DevBoxNextAction;
};

export type DevBoxNextAction = DevBoxNextActionContract;

/**
 * Map types
 */

export type DevBoxActionMap = SerializableMap<DevBoxAction>;
export type DevBoxToDevBoxActionMap = SerializableMap<DevBoxActionMap>;

// TODO: add arguments to function a la serializable-map if they're ever needed
export const DevBoxActionMap = (): DevBoxActionMap => ({});
export const DevBoxToDevBoxActionMap = (): DevBoxToDevBoxActionMap => ({});

export type DevBoxActionNiceName = 'StopOnDisconnect' | 'ScheduleDefault';
export type DevBoxActionName = 'idle-stopondisconnect' | 'schedule-default';
export const DevBoxActionName: UnionValueMap<DevBoxActionNiceName, DevBoxActionName> = {
    StopOnDisconnect: 'idle-stopondisconnect',
    ScheduleDefault: 'schedule-default',
};
