import { FeatureFlagName } from '../../constants/features';
import { FeatureFlagSet } from '../../models/features';
import { DevMenuFormData, DevMenuFormFieldData } from './models';

const getDevMenuFormFieldDataForFlag = (
    enabledFeatures: FeatureFlagSet,
    frozenFeatures: FeatureFlagSet,
    flag: FeatureFlagName
): DevMenuFormFieldData =>
    DevMenuFormFieldData({
        enabled: enabledFeatures[flag],
        frozen: frozenFeatures[flag],
    });

export const getDevMenuPanelInitialValues = (
    enabledFeatures: FeatureFlagSet,
    frozenFeatures: FeatureFlagSet
): DevMenuFormData => {
    const getFieldDataForFlag = (flag: FeatureFlagName): DevMenuFormFieldData =>
        getDevMenuFormFieldDataForFlag(enabledFeatures, frozenFeatures, flag);

    const formData: DevMenuFormData = {
        enableBetaDataPlane: getFieldDataForFlag(FeatureFlagName.EnableBetaDataPlane),
        underDevelopment: getFieldDataForFlag(FeatureFlagName.UnderDevelopment),
        useNonPreauthorizedScopes: getFieldDataForFlag(FeatureFlagName.UseNonPreauthorizedScopes),
        logVerbose: getFieldDataForFlag(FeatureFlagName.LogVerbose),
        enableThemeModeToggle: getFieldDataForFlag(FeatureFlagName.EnableThemeModeToggle),
        singleDevCenterMode: getFieldDataForFlag(FeatureFlagName.SingleDevCenterMode),
        displayNames: getFieldDataForFlag(FeatureFlagName.DisplayNames),
        roundTripTime: getFieldDataForFlag(FeatureFlagName.RoundTripTime),
        enableDiscoveryService: getFieldDataForFlag(FeatureFlagName.EnableDiscoveryService),
        enableIntrinsicTasksForCustomization: getFieldDataForFlag(FeatureFlagName.EnableIntrinsicTasksForCustomization),
        enableGuestUser: getFieldDataForFlag(FeatureFlagName.EnableGuestUser),
        enableOffloadDevBox: getFieldDataForFlag(FeatureFlagName.EnableOffloadDevBox),
        enableForceDelete: getFieldDataForFlag(FeatureFlagName.EnableForceDelete),
    };

    return DevMenuFormData(formData);
};

export const getFeatureFlagSetFromFormData = (formData: DevMenuFormData): FeatureFlagSet => {
    const featureFlagSet: FeatureFlagSet = {
        EnableBetaDataPlane: formData.enableBetaDataPlane.enabled,
        UnderDevelopment: formData.underDevelopment.enabled,
        UseNonPreauthorizedScopes: formData.useNonPreauthorizedScopes.enabled,
        LogVerbose: formData.logVerbose.enabled,
        EnableThemeModeToggle: formData.enableThemeModeToggle.enabled,
        SingleDevCenterMode: formData.singleDevCenterMode.enabled,
        DisplayNames: formData.displayNames.enabled,
        RoundTripTime: formData.roundTripTime.enabled,
        EnableDiscoveryService: formData.enableDiscoveryService.enabled,
        EnableIntrinsicTasksForCustomization: formData.enableIntrinsicTasksForCustomization.enabled,
        EnableGuestUser: formData.enableGuestUser.enabled,
        EnableOffloadDevBox: formData.enableOffloadDevBox.enabled,
        EnableForceDelete: formData.enableForceDelete.enabled,
    };

    return FeatureFlagSet(featureFlagSet);
};
