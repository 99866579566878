import {
    ConfirmationDialogContext,
    ConfirmationDialogProperties,
} from '../../components/common/confirmation-dialog/contexts';
import { ConfigureConnectionDialogContext } from '../../components/dev-box/configure-connection-dialog/contexts';
import {
    DelayShutdownDialogContext,
    DelayShutdownDialogProperties,
} from '../../components/dev-box/delay-shutdown-dialog/contexts';
import {
    HibernatePreviewDialogContext,
    HibernatePreviewDialogProperties,
} from '../../components/dev-box/hibernate-preview-dialog/context';
import { RepairDialogContext, RepairDialogProperties } from '../../components/dev-box/repair-dialog/context';
import {
    RestoreSnapshotDialogContext,
    RestoreSnapshotDialogProperties,
} from '../../components/dev-box/restore-snapshot-dialog/contexts';
import {
    ChangeEnvironmentExpirationDialogContext,
    ChangeEnvironmentExpirationDialogProperties,
} from '../../components/environment/change-expiration-dialog/contexts';
import {
    ForceDeleteDialogContext,
    ForceDeleteDialogProperties,
} from '../../components/environment/force-delete-dialog/contexts';
import {
    RedeployEnvironmentDialogContext,
    RedeployEnvironmentDialogProperties,
} from '../../components/environment/redeploy-environment-dialog/contexts';
import {
    ConnectViaAppDialogContext,
    OpenConnectViaAppDialogProperties,
} from '../../components/user-settings/connect-via-app-dialog/contexts';
import { DialogName } from '../../constants/app';
import { AsyncState } from '../../redux/store/common-state';
import {
    defaultCloseSurfaceHandler,
    defaultOpenSurfaceHandler,
    defaultOpenSurfaceHandlerWithProperties,
    surfaceContextHook,
    surfaceContextWithPropertiesHook,
} from './common';

const defaultCloseDialogHandler = defaultCloseSurfaceHandler;
const defaultOpenDialogHandler = defaultOpenSurfaceHandler;
const defaultOpenDialogHandlerWithProperties = defaultOpenSurfaceHandlerWithProperties;

const defaultOnSubmit = () => {
    return;
};

const defaultCloseConfigureConnectionDialogHandler = defaultCloseDialogHandler(DialogName.ConfigureConnection);
const defaultOpenConfigureConnectionDialogHandler = defaultOpenDialogHandler(DialogName.ConfigureConnection);
const defaultCloseConnectViaAppDialogHandler = defaultCloseDialogHandler(DialogName.ConnectViaApp);
const defaultOpenConnectViaAppDialogHandler = defaultOpenDialogHandler(DialogName.ConnectViaApp);
const defaultCloseDelayShutdownDialogHandler = defaultCloseDialogHandler(DialogName.DelayShutdown);
const defaultOpenDelayShutdownDialogHandler = defaultOpenDialogHandlerWithProperties(DialogName.DelayShutdown);
const defaultCloseRestoreSnapshotDialogHandler = defaultCloseDialogHandler(DialogName.Restore);
const defaultOpenRestoreSnapshotDialogHandler = defaultOpenDialogHandlerWithProperties(DialogName.Restore);
const defaultCloseConfirmationDialogHandler = defaultCloseDialogHandler(DialogName.Confirmation);
const defaultOpenConfirmationDialogHandler = defaultOpenDialogHandlerWithProperties(DialogName.Confirmation);
const defaultCloseForceDeleteDialogHandler = defaultCloseDialogHandler(DialogName.ForceDelete);
const defaultOpenForceDeleteDialogHandler = defaultOpenDialogHandlerWithProperties(DialogName.ForceDelete);
const defaultCloseRedeployEnvironmentDialogHandler = defaultCloseDialogHandler(DialogName.RedeployEnvironment);
const defaultOpenRedeployEnvironmentDialogHandler = defaultOpenDialogHandlerWithProperties(
    DialogName.RedeployEnvironment
);
const defaultCloseHibernatePreviewDialogHandler = defaultCloseDialogHandler(DialogName.HibernatePreview);
const defaultOpenHibernatePreviewDialogHandler = defaultOpenDialogHandler(DialogName.HibernatePreview);
const defaultCloseRepairDialogHandler = defaultCloseDialogHandler(DialogName.Repair);
const defaultOpenRepairDialogHandler = defaultOpenDialogHandler(DialogName.Repair);
const defaultCloseChangeEnvironmentExpirationDialogHandler = defaultCloseDialogHandler(
    DialogName.ChangeEnvironmentExpiration
);
const defaultOpenChangeEnvironmentExpirationDialogHandler = defaultOpenDialogHandlerWithProperties(
    DialogName.ChangeEnvironmentExpiration
);

export const useConfigureConnectionDialogContext = surfaceContextHook(
    ConfigureConnectionDialogContext,
    defaultOpenConfigureConnectionDialogHandler,
    defaultCloseConfigureConnectionDialogHandler
);

export const useDelayShutdownDialogContext = surfaceContextWithPropertiesHook<DelayShutdownDialogProperties>(
    DelayShutdownDialogContext,
    defaultOpenDelayShutdownDialogHandler,
    defaultCloseDelayShutdownDialogHandler,
    {
        devBoxId: '',
        locale: '',
        originalScheduledTime: new Date(),
        onDelaySubmitted: defaultOnSubmit,
        onSkipSubmitted: defaultOnSubmit,
        hasHibernateSupport: true,
        delayState: AsyncState.NotStarted,
        skipState: AsyncState.NotStarted,
        nextScheduledTime: new Date(),
    }
);

export const useRestoreSnapshotDialogContext = surfaceContextWithPropertiesHook<RestoreSnapshotDialogProperties>(
    RestoreSnapshotDialogContext,
    defaultOpenRestoreSnapshotDialogHandler,
    defaultCloseRestoreSnapshotDialogHandler,
    {
        devBoxId: '',
        snapshots: [],
        locale: '',
        onRestoreSubmitted: defaultOnSubmit,
    }
);

export const useRedeployEnvironmentDialogContext =
    surfaceContextWithPropertiesHook<RedeployEnvironmentDialogProperties>(
        RedeployEnvironmentDialogContext,
        defaultOpenRedeployEnvironmentDialogHandler,
        defaultCloseRedeployEnvironmentDialogHandler,
        {
            environment: {
                environmentType: '',
                catalogName: '',
                environmentDefinitionName: '',
                name: '',
                uri: '',
            },
        }
    );

export const useChangeEnvironmentExpirationDialogContext =
    surfaceContextWithPropertiesHook<ChangeEnvironmentExpirationDialogProperties>(
        ChangeEnvironmentExpirationDialogContext,
        defaultOpenChangeEnvironmentExpirationDialogHandler,
        defaultCloseChangeEnvironmentExpirationDialogHandler,
        {
            environment: {
                environmentType: '',
                catalogName: '',
                environmentDefinitionName: '',
                name: '',
                uri: '',
            },
        }
    );

export const useForceDeleteDialogContext = surfaceContextWithPropertiesHook<ForceDeleteDialogProperties>(
    ForceDeleteDialogContext,
    defaultOpenForceDeleteDialogHandler,
    defaultCloseForceDeleteDialogHandler,
    {
        onPrimaryButtonSubmitted: defaultOnSubmit,
        primaryButtonText: '',
        subText: '',
        title: '',
    }
);

export const useConfirmationDialogContext = surfaceContextWithPropertiesHook<ConfirmationDialogProperties>(
    ConfirmationDialogContext,
    defaultOpenConfirmationDialogHandler,
    defaultCloseConfirmationDialogHandler,
    {
        onPrimaryButtonSubmitted: defaultOnSubmit,
        primaryButtonText: '',
        subText: '',
        title: '',
    }
);

export const useHibernatePreviewDialogContext = surfaceContextWithPropertiesHook<HibernatePreviewDialogProperties>(
    HibernatePreviewDialogContext,
    defaultOpenHibernatePreviewDialogHandler,
    defaultCloseHibernatePreviewDialogHandler,
    { devBoxName: '', onHibernateSubmitted: () => void {} }
);

export const useRepairDialogContext = surfaceContextWithPropertiesHook<RepairDialogProperties>(
    RepairDialogContext,
    defaultOpenRepairDialogHandler,
    defaultCloseRepairDialogHandler,
    { onRepairSubmitted: () => void {} }
);

export const useConnectViaAppDialogContext = surfaceContextWithPropertiesHook<OpenConnectViaAppDialogProperties>(
    ConnectViaAppDialogContext,
    defaultOpenConnectViaAppDialogHandler,
    defaultCloseConnectViaAppDialogHandler,
    { cloudPcConnectionUrl: '', remoteDesktopUrl: '' }
);
